<template>
    <a-form layout="vertical" :model="formState" :rules="rules" ref="formRef">
      <a-form-item name="mobile">
        <a-input
          v-model:value.trim="formState.mobile"
          :placeholder="$t('login.请输入手机号')"
          size="large"
        >
        </a-input>
      </a-form-item>
      <!-- 验证码 -->
      <a-form-item name="validCode">
        <!-- 请输入验证码 -->
        <a-input
          v-model:value.trim="formState.validCode"
          :placeholder="$t('login.请输入验证码')"
          size="large"
          :maxlength="10"
          :disabled="state.mobileRight ? false : true"
        >
          <template #suffix>
            <a-button type="link" size="mini" @click="getCode" :disabled="state.codeBthDisabled">
              <!-- 获取验证码 -->
              {{ $t('login.get_code') }}
              <span v-show="state.codeBthDisabled">
                ({{ state.s }}s)
              </span>
            </a-button>
          </template>
        </a-input>
      </a-form-item>
      <a-form-item>
        <!-- 登录 -->
        <a-button
          type="primary"
          size="large"
          block
          @click="fnLogin"
          :loading="state.loading"
        >
          {{ $t("CM_SignIn") }}
        </a-button>
      </a-form-item>
    </a-form>
  </template>
  <script setup>
  import { useI18n } from "vue-i18n";
  import { ref, getCurrentInstance, onUnmounted, reactive } from 'vue';
  import { validatorMobile } from '@/utils/formRules';
  import { currentHost } from "@/utils/request.js";
  import { sendcode, CaptchaLogin } from '@/api/user';
  
  const { t: $t } = useI18n();
  const { proxy } = getCurrentInstance();
  const emit = defineEmits(["emit"]);
  
  const state = reactive({
    mobileRight: false,
    timer: null,
    s: 0,
    codeBthDisabled: false,
    loading: false,
  })
  const formRef = ref(null);
  const formState = reactive({
    mobile: null,
    validCode: null,
  })
  const rules = {
    mobile: [
      { required: true, message: $t('login.请输入手机号'), trigger: 'blur' },
      { validator: validatorMobile, trigger: 'change' }
    ],
    validCode: [
      { required: true, message: $t('login.请输入验证码'), trigger: 'blur' },
    ],
  };
  async function getCode() {
    formRef.value
      .validate(['mobile'])
      .then(async () => {
        const res = await sendcode({
          mobile: formState.mobile,
          site: currentHost,
          isLogin: 1,
        });
        if (res.ret === 0) {
          proxy.$message.success(res.msg);
          state.mobileRight = true;
          state.s = 60;
          state.codeBthDisabled = true;
          state.timer = setInterval(() => {
            if (state.s === 0) {
              clearTimer();
              return false;
            }
            state.s -= 1;
          }, 1000);
        } else {
          //proxy.$message.error(res.msg);
        }
      })
  };
  function clearTimer() {
    clearInterval(state.timer);
    state.timer = null;
    state.s = 0;
    state.codeBthDisabled = false;
  };
  function fnLogin() {
    formRef.value
      .validate()
      .then(async () => {
        const params = {
          mobile: formState.mobile,
          site: currentHost,
          validCode: formState.validCode,
          platform: 1,
          loginType: 2,
        }
        state.loading = true;
        const res = await CaptchaLogin(params);
        if (res.ret === 0) {
          state.loading = false;
          emit('on-success', res);
        } else {
          state.loading = false;
          proxy.$message.error(res.msg);
        }
      })
  };
  onUnmounted(() => {
    clearTimer();
  });
  </script>
  <style lang="less" scoped>
  ::v-deep(.ant-btn-lg) {
    line-height: 41px;
    height: 54px;
    background: var(--theme);
    border-color: var(--theme);
    margin-top: 10px;
    border-radius: 2px;
    font-size: 18px;
  }
  ::v-deep(.ant-input) {
    line-height: 46px;
  }
  ::v-deep(.ant-btn-link) {
    border-width: 0;
  }
  </style>