<template>
  <div class="ding-qr">
    <div class="qr-content">
      <QrcodeVue :value="qrLink" :size="210" level="H" />
    </div>
    <div class="qr-txt">
      {{ $t('login.use_palform_scan', [platformName]) }}
      <!-- 请使用  扫描二维码登录-->
      <span class="refresh" @click.stop="refreshCode()"><SyncOutlined />{{ $t('login.refresh') }}</span>
      <!-- 刷新 -->
    </div>
    <div class="qr-refresh" v-show="isExpire">
      {{ $t('login.your_q_r_code_is_invalid') }}
      <!-- 您的二维码已失效 -->
      <br />
      {{ $t('login.please_click_the_refresh_button_below') }}
      <!-- 请点击下方刷新按钮 -->
    </div>
  </div>
</template>
<script>
import { useI18n } from 'vue-i18n';
import { reactive, onMounted, getCurrentInstance, toRefs, computed, onUnmounted } from 'vue';
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { creatDingQR, getScanState } from "@/api/user";
import { successCallback } from "@/utils/login.js";
import QrcodeVue from "qrcode.vue";
export default {
  components: {
    QrcodeVue,
  },
  setup() {
    const { t: $t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const config = computed(() => {
      return store.getters.platformConfig;
    });
    const platformInfo = {
      20: $t('login.wx'), //微信
      30: $t('login.ding'), //钉钉
    };
    let platformName = platformInfo[config.value.platform];
    const state = reactive({
      key: route.query.key || "",
      redirect: decodeURIComponent(route.query.redirect || "/"),
      code: "",
      qrLink: "",
      isExpire: false, // 是否过期
      timer: null,
    });

    /**
     *
     * 钉钉扫码登录流程
     * 1.PC端 获取code 生成一个五分钟二维码链接 链接指向H5确认页面
     * 2.进入确认页面 -- 调接口 -- 我已扫码
     * 3.再进行钉钉授权 --再跳到登录确认页面
     * 4.点击登录确认 -- 调接口 -- 授权登录成功
     * 4.PC 轮询 查询状态 状态为0 扫码登录成功 跳转首页
     */
    const getCode = () => {
      creatDingQR().then((res) => {
        if (res.ret === 0 && res.data) {
          state.isExpire = false;
          state.code = res.data;
          state.qrLink = `${location.protocol}//${config.value.mobileDomain}/view/login/dingqr?code=${res.data}`;
          // state.qrLink = `http://192.168.3.42:8080/view/login/dingqr?code=${res.data}`;
          // 5 分钟后过期
          setTimeout(() => {
            state.isExpire = true;
            clearTimer();
          }, 1000 * 59 * 5);
          // 轮询获取状态 看是否登录
          state.timer = setInterval(() => {
            getState();
          }, 4000);
        } else {
          proxy.$message.error($t('login.failed_to_create_q_r_code'));
          // 创建二维码失败
        }
      });
    };

    const refreshCode = () => {
      if (!state.isExpire) return;
      getCode();
    };

    const getState = () => {
      getScanState({ key: state.code }).then((res) => {
        if (res.ret === 0) {
          if (res.data.state === 0) {
            console.log(res.data, "登录成功");
            clearTimer();
            successCallback(res, state);
          } else if ([12, 13].includes(res.data.state)) {
            state.isExpire = true;
            clearTimer();
            proxy.$message.error($t('login.login_failed'));
            // 登录失败
          }
        } else {
          console.info("二维码过期");
          state.isExpire = true;
          clearTimer();
        }
      });
    };

    const clearTimer = () => {
      clearInterval(state.timer);
      state.timer = null;
    };

    onMounted(() => {
      getCode();
    });
    onUnmounted(() => {
      clearTimer();
    });

    return {
      ...toRefs(state),
      refreshCode,
      platformName,
    };
  },
};
</script>

<style lang="less" scoped>
.ding-qr {
  position: relative;
  height: 260px;
  .qr-content {
    width: 210px;
    height: 210px;
    margin: auto;
  }
  .qr-txt {
    margin-top: 20px;
    color: #898d90;
    font-size: 14px;
    text-align: center;
    .refresh {
      color: #38adff;
      cursor: pointer;
    }
  }
  .qr-refresh {
    position: absolute;
    top: 0px;
    left: 50%;
    width: 210px;
    height: 210px;
    background: rgba(255, 255, 255, 0.9);
    z-index: 100;
    text-align: center;
    padding-top: 90px;
    color: #fa5b5b;
    font-size: 14px;
    margin-left: -105px;
  }
}
</style>
